//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { PrescriptionService } from '@/services/prescription.service';
export default {
  props: {
    rowData: {
      type: Object,

      default() {
        return {};
      }

    }
  },
  watch: {
    showDialog(show) {
      if (!show) {
        this.alert.show = false;
      }
    }

  },

  data() {
    return {
      showDialog: false,
      alert: {
        show: false,
        message: ''
      },
      buttonDisabled: false
    };
  },

  methods: {
    async retryPrescription() {
      this.alert.show = false;

      try {
        this.buttonDisabled = true;
        const {
          data
        } = await PrescriptionService.retryPayment(this.rowData.id);

        if (data) {
          this.buttonDisabled = false;
          this.$emit('refresh-prescriptions');
          this.showDialog = false;
        }
      } catch (err) {
        this.buttonDisabled = false;
        this.alert.message = err;
        this.alert.show = true;
      }
    }

  }
};